import { util } from '@/utils/';
import http from '../request/index';

export default {

  createCustomer(data) {
    return http.post('/app-api/mp/customer/createCustomer', data);
  },

  // 顾客分页
  getCustomerList(data) {
    const url = util.urlAddParams('/app-api/customer/userinfo/page', data);
    return http.get(url);
  },

  // 顾客分页
  getCustomerDetail(data) {
    const url = util.urlAddParams('/app-api/customer/userinfo/get', data);
    return http.get(url);
  },

  //分页查询获得顾客检测记录 
  getCustomerReportList(data){
    const url = util.urlAddParams('/app-api/customer/detect-record/page',data)
    return http.get(url);
  },

  //一键查询顾客所有的检测记录
  getCustomerReportAll(data){
    return http.post('/app-api/customer/detect-record/create',data)
  },

  // 获得咨询师，客户和医生沟通分页
  getCommunicateList(data) {
    const url = util.urlAddParams('/app-api/customer/communicate/page', data);
    return http.get(url);
  },

  // 获得客户修脸方案列表
  getModifywaysList(data) {
    const url = util.urlAddParams('/app-api/customer/programme/page', data);
    return http.get(url);
  },

  // 沟通记录主菜单分页
  communicateCustomerPage(data) {
    const url = util.urlAddParams('/app-api/customer/communicate/communicateCustomerPage', data);
    return http.get(url);
  },

  // 获得咨询师，客户和医生沟通不分页
  getCommunicateListNoPage(data) {
    const url = util.urlAddParams('/app-api/customer/communicate/list', data);
    return http.get(url);
  },

  // 获得咨询师，客户和医生沟通详情
  getCommunicateDetail(data) {
    const url = util.urlAddParams('/app-api/customer/communicate/get', data);
    return http.get(url);
  },

  // 获得顾客详情
  getCustomerInfo(data) {
    const url = util.urlAddParams('/app-api/customer/userinfo/get', data);
    return http.get(url);
  },

  // 获得咨询师|医生详情
  getUserInfo(data) {
    const url = util.urlAddParams('/app-api/system/user/get', data);
    return http.get(url);
  },
  // getCustomerCommunicatePage(data) {
  //   const url = util.urlAddParams('/app-api/customer/communicate/page', data);
  //   return http.get(url);
  // },
  //获得上次用户选择的机构和门店
  getTenantinfo(data){
    const url = util.urlAddParams('/app-api/customer/dept-stay/getByOpenid',data);
    return http.get(url);
  },

  //更新用户选择的门店和机构
  updateTenantinfo(data){
    const url = util.urlAddParams('/app-api/customer/dept-stay/update',data);
    return http.put(url)
  },
 
  //选中客户门店停留信息
  getChoseinfo(data){
    const url = util.urlAddParams('/app-api/customer/dept-stay/getChosen',data);
    return http.get(url)
  },

  //获得客户停留门店信息
  getStayDeptlist(data){
    const url = util.urlAddParams('/app-api/customer/dept-stay/getStayDeptList',data);
    return http.get(url)
  },

  //获得客户停留机构信息
  getStayTenantlist(data){
    const url = util.urlAddParams('/app-api/customer/dept-stay/getStayTenantList',data);
    return http.get(url)
  },

  //创建微信jssdk所需要的签名
  getWxSignature(data){
    const url = util.urlAddParams('/app-api/system/auth/create-jsapi-signature',data);
    return http.post(url)
  },

  //更新用户选择的门店
  updateChoseteaent(data){
    // const url = util.urlAddParams('/app-api/customer/dept-stay/chooseDeptStay',data);
    return http.post('/app-api/customer/dept-stay/chooseDeptStay',data)
  },

  // wxMpAuthToken(data) {
	// 	return http.post('/app-api/system/auth/wxMpAuthToken', data);
	// }

  //通过手机号加密串获得用户角色门店列表
  getRolesByKey(data){
    const url = util.urlAddParams('/app-api/system/dept/getUserDeptRoleList'.data)
    return http.get(url)
  },

    //获得客户的修脸方案
    getShaveRecord(data){
      const url = util.urlAddParams('/app-api/customer/shave-record/page',data)
      return http.get(url)
    },
  
    //通过id获取修脸记录详情
    getModifyDetail(data){
      const url = util.urlAddParams('/app-api/customer/shave-record/get',data)
      return http.get(url)
    },

    //医生核销接口
    createverification(data){
      return http.post('/app-api/pay/order/verify', data);
    },
    //更新医生个人信息
    updateUserInfo(data){
      return http.post('/app-api/system/user/modifyUserBase',data)
    }
};
