import { util } from '@/utils/';
import http from '../request/index';

export default {

  // 获取机构的商品分类列表
  getGoodsList(data) {
    const url = util.urlAddParams('/app-api/prod/mall-product/productCategoryList', data);
    return http.get(url);
  },

//   //根据商品分类id获得对应的商品
//   getGoodsById(data){

//   }
};
