import { defineStore } from 'pinia';
// 1. 创建store
// 参数1：store的唯一表示
// 参数2：对象，可以提供state actions getters
const useUserStore = defineStore('user', {
	persist: {
		storage: sessionStorage
	},
	state: () => ({
		userInfo: {
			mpAuthType: '',
			tenantId: '',
			deptId: '',
			shopId: '',
			name: '', // 用户名
			userimg: '',
			deptId2:''
		}
	}),
	getters: {
		isConsultant(state) {
			return state.userInfo.mpAuthType === 'consultant';
		},

		isCustomer(state) {
			return state.userInfo.mpAuthType === 'customer';
		},

		isManager(state) {
			return state.userInfo.mpAuthType === 'manager';
		},

		isDoctor(state) {
			return state.userInfo.mpAuthType === 'doctor';
		}
	},
	actions: {
		setMpAuthType(data) {
			this.mpAuthType = data;
		},
		setTenantId(data) {
			this.tenantId = data;
		},
		setDeptId(data) {
			this.deptId = data;
		},
		setShopId(data) {
			this.shopId = data;
		},
		setUserInfo(data) {
			this.userInfo = data;
		}
	}
});

export default useUserStore;
