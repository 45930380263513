export default {
	inserted(el, binding) {
		let timeout;
		el.addEventListener('input', () => {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				binding.value();
			}, parseInt(binding.arg, 10) || 300);
		});
	}
};
