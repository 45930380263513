import { util } from '@/utils/';
import http from '../request/index';

export default {

  // 店长获得门店顾客成交量排行榜
  deptCustomerRankingPage(data) {
    const url = util.urlAddParams('/app-api/customer/mall-customer/deptCustomerRankingPage', data);
    return http.get(url);
  },
  // 店长获得门店客户成交统计
  deptCustomerDealTotal(data) {
    const url = util.urlAddParams('/app-api/customer/mall-customer/deptCustomerDealTotal', data);
    return http.get(url);
  },
  // 店长获得门店客户成交分页
  deptCustomerDealPage(data) {
    const url = util.urlAddParams('/app-api/customer/mall-customer/deptCustomerDealPage', data);
    return http.get(url);
  },
  // 获得门店成交项目排行榜
  deptProductRankingPage(data) {
    const url = util.urlAddParams('/app-api/prod/mall-product/deptProductRankingPage', data);
    return http.get(url);
  },
  // 店长-门店项目成交详情-分页
  deptProductDealPage(data) {
    const url = util.urlAddParams('/app-api/prod/mall-product/deptProductDealPage', data);
    return http.get(url);
  },
  // 获得门店项目成交分页
  deptProductDealTotal(data) {
    const url = util.urlAddParams('/app-api/prod/mall-product/deptProductDealTotal', data);
    return http.get(url);
  },
  // 店长|咨询师-新客注册数-分页
  customerRegisterPage(data) {
    const url = util.urlAddParams('/app-api/customer/mall-customer/customerRegisterPage', data);
    return http.get(url);
  },
  // 咨询师-客户成交量排行榜-分页
  customerRankingPage(data) {
    const url = util.urlAddParams('/app-api/customer/mall-customer/customerRankingPage', data);
    return http.get(url);
  },
  // 咨询师-成交项目排行榜-分页
  productRankingPage(data) {
    const url = util.urlAddParams('/app-api/prod/mall-product/productRankingPage', data);
    return http.get(url);
  },
  // 店长|咨询师-客户检测数-分页
  customerDetectPage(data) {
    const url = util.urlAddParams('/app-api/customer/mall-customer/customerDetectPage', data);
    return http.get(url);
  },
  // 店长|咨询师-客户检测数-汇总
  customerDetectTotal(data) {
    const url = util.urlAddParams('/app-api/customer/mall-customer/customerDetectTotal', data);
    return http.get(url);
  },
  // 咨询师-成交客户详情-分页
  customerDealPage(data) {
    const url = util.urlAddParams('/app-api/customer/mall-customer/customerDealPage', data);
    return http.get(url);
  },
  // 咨询师-成交客户详情-汇总
  customerDealTotal(data) {
    const url = util.urlAddParams('/app-api/customer/mall-customer/customerDealTotal', data);
    return http.get(url);
  },
  // 咨询师-成交项目详情-汇总
  productDealTotal(data) {
    const url = util.urlAddParams('/app-api/prod/mall-product/productDealTotal', data);
    return http.get(url);
  },
  // 咨询师-成交项目详情-分页
  productDealPage(data) {
    const url = util.urlAddParams('/app-api/prod/mall-product/productDealPage', data);
    return http.get(url);
  },

  // 咨询师|医生-成交订单详情-分页
  orderDealPage(data) {
    const url = util.urlAddParams('/app-api/performance/mall-performance/orderDealPage', data);
    return http.get(url);
  },
  // 店长-成交订单详情-分页
  deptOrderDealPage(data) {
    const url = util.urlAddParams('/app-api/performance/mall-performance/deptOrderDealPage', data);
    return http.get(url);
  },
  // 咨询师-门店成交订单详情-汇总
  orderDealTotal(data) {
    const url = util.urlAddParams('/app-api/performance/mall-performance/orderDealTotal', data);
    return http.get(url);
  },
  // 店长-门店成交订单详情-汇总
  orderDealTotalForManager(data) {
    const url = util.urlAddParams('/app-api/performance/mall-performance/deptOrderDealTotal', data);
    return http.get(url);
  },
  // 咨询师-合作医生排行榜-分页
  getDoctorRankingPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/doctorRankingPage', data);
    return http.get(url);
  },

  // 咨询师-合作医生排行榜详情-分页
  getDoctorDealPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/cooDealPage', data);
    return http.get(url);
  },

  // 咨询师-合作医生排行榜详情-汇总
  getDoctorDealPageToale(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/cooDealTotal', data);
    return http.get(url);
  },

  // 咨询师-合作渠道排行榜-分页
  getHospitalRankingPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/hospitalRankingPage', data);
    return http.get(url);
  },

  // 咨询师-合作渠道排行榜详情-分页
  getHospitalDealPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/cooDealPage', data);
    return http.get(url);
  },

  // 咨询师-合作渠道排行榜详情-汇总
  getHospitalDealTotal(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/cooDealTotal', data);
    return http.get(url);
  },

  // 咨询师-合作医生复购排行榜-分页
  getDoctorRepurchaseRankingPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/doctorRepurchaseRankingPage', data);
    return http.get(url);
  },

  // 咨询师-合作医生复购排行榜详情-分页
  getCooDoctorRepurchaseDealPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/cooDoctorRepurchaseDealPage', data);
    return http.get(url);
  },

  // 咨询师-合作医生复购排行榜详情-汇总
  getCooDoctorRepurchaseDealTotal(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/cooDoctorRepurchaseDealTotal', data);
    return http.get(url);
  },

  // 医生-合作门店排行榜-分页
  getCooDeptRankingPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/cooDeptRankingPage', data);
    return http.get(url);
  },

  // 医生-合作门店排行榜详情-分页
  getCooDeptDealPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/cooDealPage', data);
    return http.get(url);
  },

  // 医生-合作门店排行榜详情-汇总
  getCooDeptDealTotal(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/cooDealTotal', data);
    return http.get(url);
  },

  // 医生-合作咨询师复购排行榜-分页
  getConsultantRepurchaseRankingPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/consultantRepurchaseRankingPage', data);
    return http.get(url);
  },

  // 医生-合作咨询师复购排行榜详情-分页
  getConsultantRepurchaseDealPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/cooConsultantRepurchaseDealPage', data);
    return http.get(url);
  },

  // 医生-合作咨询师复购排行榜详情-汇总
  getConsultantRepurchaseDealTotal(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/cooConsultantRepurchaseDealTotal', data);
    return http.get(url);
  },

  // 医生-咨询师成交排行榜-分页
  getConsultantDealRankingPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/consultantDealRankingPage', data);
    return http.get(url);
  },

  // 医生-咨询师成交排行榜详情-分页
  getConsultantDealPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/cooDealPage', data);
    return http.get(url);
  },

  // 医生-咨询师成交排行榜详情-汇总
  getConsultantDealTotal(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/cooDealTotal', data);
    return http.get(url);
  },
  // 店长-合作渠道排行榜-分页
  deptHospitalRankingPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/deptHospitalRankingPage', data);
    return http.get(url);
  },
  // 店长-合作医生排行榜-分页
  deptDoctorRankingPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/deptDoctorRankingPage', data);
    return http.get(url);
  },
  // 店长-合作医生复购排行榜-分页
  deptDoctorRepurchaseRankingPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/deptDoctorRepurchaseRankingPage', data);
    return http.get(url);
  },
  // 店长-合作渠道|医生详情-汇总
  deptCooDealTotal(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/deptCooDealTotal', data);
    return http.get(url);
  },
  // 店长-合作渠道|医生详情-分页
  deptCooDealPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/deptCooDealPage', data);
    return http.get(url);
  },
   // 店长-合作医生复购详情-分页
   deptCooDoctorRepurchaseDealPage(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/deptCooDoctorRepurchaseDealPage', data);
    return http.get(url);
  },
   // 店长-合作医生复购详情-汇总
   deptCooDoctorRepurchaseDealTotal(data) {
    const url = util.urlAddParams('/app-api/pay/mall-hospital/deptCooDoctorRepurchaseDealTotal', data);
    return http.get(url);
  },
};
