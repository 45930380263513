import { util } from '@/utils/';
import http from '../request/index';

export default {
	//奖牌分数列表
	getStandardScore() {
		const url = util.urlAddParams('/app-api/market/standard-score/get');
		return http.get(url);
	},
	//题目列表
	listByMarketUser(data) {
		return http.post('/app-api/market/structure/listByMarketUser', data);
	},
	//题目详情
	studyCourse(data) {
		return http.post('/app-api/market/course/studyCourse', data);
	}
};
