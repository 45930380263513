import http from '../request/index';

export default {
	// 获得最新app
	getAppVersionNew() {
		return http.get('/app-api/system/app-version/getNew', { params: { code: '5d-mirror' } });
	},
	// 获得视频列表
	getVideoList() {
		return http.get('/admin-api/system/video/list', {
			headers: {
				'tenant-id': 1
			}
		});
	},
};
