import { util } from '@/utils/';
import http from '../request/index';

export default {
	// 打分
	finalScoring(data) {
		return http.post('/app-api/system/competition-final-scoring/scoring', data);
	},

	// 获得决赛评委列表
	getJudgeAllList(data) {
		const url = util.urlAddParams('/app-api/system/competition-final-judge/getAllList', data);
		return http.get(url);
	},
	// 获得决赛打分列表
	getScoringList(data) {
		const url = util.urlAddParams('/app-api/system/competition-final-scoring/listBy', data);
		return http.get(url);
	}
};
