import { util } from '@/utils/';
import http from '../request/index';

export default {

  // 获取用户的检测记录
  getreportdetail(data) {
    const url = util.urlAddParams('/app-api/customer/detect-record/get', data);
    return http.get(url);
  },
};
