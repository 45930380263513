import { util } from '@/utils/';
import http from '../request/index';

export default {
	// 发送手机验证码
	sendSMSCode(data) {
		return http.post('/app-api/system/auth/send-sms-code', data);
	},
	//获得个人信息
	getUserInfo(data) {
		const url = util.urlAddParams('/app-api/customer/userinfo/get', data);
		return http.get(url);
	},
	//更新用户信息
	updateUserInfo(data) {
		return http.put('/app-api/customer/userinfo/update', data);
	},

	uploadImg(data) {
		return http.post('/app-api/infra/file/upload', data);
	},
	//手机验证码登录
	loginByCode(data) {
		return http.post('/app-api/system/auth/userSmsLogin', data);
	},
	// //手机验证码登录
	// loginByCode(data) {
	// 	return http.post('/app-api/system/auth/competitionSmsLogin', data);
	// },
	//获得人员
	getMarketInfo(data) {
		const url = util.urlAddParams('/app-api/market/user/getByUserId', data);
		return http.get(url);
	},
	createMarket(data) {
		return http.post('/app-api/market/user/create', data);
	},
	updateMarket(data) {
		return http.put('/app-api/market/user/update', data);
	},
	getShopList(data) {
		return http.post('/app-api/market/shop/page', data);
	},
	delShop(data) {
		const url = util.urlAddParams('/app-api/market/shop/delete', data);
		return http.delete(url);
	},

	createMarketShop(data) {
		return http.post('/app-api/market/shop/create', data);
	},
	getMarketShop(data) {
		const url = util.urlAddParams('/app-api/market/shop/get', data);
		return http.get(url);
	},
	updateMarketShop(data) {
		return http.put('/app-api/market/shop/update', data);
	}
	// uploadAvat(data){
	//   return http.post('/app-api/infra/file/upload', data);
	// }
};
