import { util } from '@/utils/';
import http from '../request/index';

export default {

  // 获得产品与分类展示列表
  getGoodsShowRespVoList(data) {
    const url = util.urlAddParams('/app-api/prod/mall-product/goodsShowRespVoList', data);
    return http.get(url);
  },

  // 获得产品列表
  getGoodsPage(data) {
    const url = util.urlAddParams('/app-api/prod/mall-product/productPage', data);
    return http.get(url);
  },

};
