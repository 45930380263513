import '@/directives/index';
import axios from 'axios';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { createPersistedState } from 'pinia-plugin-persistedstate';
import Vant from 'vant';
import 'vant/lib/index.less';
import 'vant/lib/icon/local.css';
import Vue from 'vue';
import VueAxios from 'vue-axios';
import VueBus from 'vue-bus';
import InlineSvg from 'vue-inline-svg';
import App from './App';
import { ip } from './config/index';
import router from './router'; // 自动扫描里面的路由配置
import './styles/index.scss';
import { Icon } from 'vant';
import wx from 'weixin-jsapi';
import { system, customer } from '@/api/index';
// Loading
import { Loading } from 'vant';
import 'vant/lib/index.css';
Vue.use(Loading);
Vue.prototype.$loading = Loading.service;

Vue.component('inline-svg', InlineSvg); // svg插件+

Vue.prototype.GLOBAL = { ip };
Vue.use(Vant);

Vue.config.productionTip = false;
Vue.use(ElementUI);

Vue.use(VueAxios, axios);

Vue.use(PiniaVuePlugin);
const pinia = createPinia();
pinia.use(createPersistedState()); // 开启缓存，存储在localstorage

Vue.use(VueBus);
Vue.use(Icon);
let newurl;
if (process.env.NODE_ENV === 'development') {
	newurl = 'http://5d.aimirainnovation.com/';
} else {
	newurl = location.href.split('#')[0];
}
// customer.getWxSignature({ url: newurl }).then((res) => {
// 	console.log('main微信签名', res.data);
// 	wx.config({
// 		debug: false,
// 		appId: res.data.appId,
// 		nonceStr: res.data.nonceStr,
// 		timestamp: res.data.timestamp.toString(),
// 		// url: "http://5d.aimirainnovation.com/",
// 		signature: res.data.signature,
// 		jsApiList: ['getLocation', 'hideMenuItems']
// 	});
// 	wx.ready(function () {
// 		//隐藏右上角分享相关的菜单
// 		wx.hideMenuItems({
// 			menuList: [
// 				'menuItem:share:appMessage',
// 				'menuItem:share:timeline',
// 				'menuItem:share:qq',
// 				'menuItem:share:QZone',
// 				'menuItem:share:facebook',
// 				'menuItem:share:weiboApp',
// 				'menuItem:copyUrl'
// 			] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
// 		});
// 		//获取用户的位置信息
// 		wx.getLocation({
// 			type: 'gcj02', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
// 			success: (res) => {
// 				var lat = res.latitude; // 纬度，浮点数，范围为90 ~ -90
// 				var lng = res.longitude; // 经度，浮点数，范围为180 ~ -180。
// 				console.log('main成功', res);
// 			},
// 			fail: (error) => {
// 				console.log('main失败', error);
// 			},
// 			cancel: (error) => {
// 				console.log('mian取消', error);
// 			}
// 		});
// 	});
// });

/* eslint-disable no-new */
new Vue({
	pinia,
	el: '#app',
	router,
	render: (h) => h(App)
});
