import { util } from '@/utils/';
import http from '../request/index';

export default {
	// 创建购物车条目
	createCartItem(data) {
		return http.post('/app-api/pay/cart-item/create', data);
	},

	// 获得购物车条目分页
	getCartList(data) {
		const url = util.urlAddParams('/app-api/pay/cart-item/page', data);
		return http.get(url);
	},

	// 更新购物车条目
	updateCartItem(data) {
		return http.put('/app-api/pay/cart-item/update', data);
	},

	// 批量删除购物车条目
	deleteCartBatch(data) {
		// const url = util.urlAddParams('/app-api/pay/cart-item/deleteBatchByIds', data);
		return http.post('/app-api/pay/cart-item/deleteBatchByIds', data);
	},

	// 获取购物车条目结算价格
	getCartItemSettlement(data) {
		const url = util.urlAddParams('/app-api/pay/cart-item/getCartItemSettlement', data);
		return http.get(url);
	},

	// 全选购物车条目
	checkAll(data) {
		return http.put('/app-api/pay/cart-item/checkAll', data);
	},

	// 创建Jspi支付订单
	createJsapiPayOrder(data) {
		return http.post('/app-api/pay/order/createJsapiPayOrder', data);
	},

	// 调起Jspi支付订单
	callJsapiPayOrder(data) {
		return http.post('/app-api/pay/order/callJsapiPayOrder', data);
	},

	// 获得app订单分页
	getOrderList(data) {
		const url = util.urlAddParams('/app-api/pay/order/page', data);
		return http.get(url);
	},

	//新版获得app订单分页
	newgetOrderList(data){
		const url = util.urlAddParams('/app-api/order/mp/mpPage',data);
		return http.get(url);
	},

	// 取消app支付订单
	getOrder(data) {
		const url = util.urlAddParams('/app-api/pay/order/getByOrderId', data);
		return http.get(url);
	},

	//新版获取订单详情
	newgetOrder(data){
		const url = util.urlAddParams('/app-api/order/mp/get', data);
		return http.get(url);
	},

	//列表获得明细id列表
	newgetOrderListId(data){
		const url = util.urlAddParams('/app-api/order/mp/listByOrderDetailIds', data);
		return http.get(url);
	},

	// 取消app支付订单
	cancelOrder(data) {
		const url = util.urlAddParams('/app-api/pay/order/cancelBySubOrderNo', data);
		return http.put(url);
	},

	//删除app已支付订单
	delOrderById(data){
		const url = util.urlAddParams('/app-api/pay/order/deleteByOrderId', data);
		return http.delete(url);
	},

	//再次购买接口
	againPay(data){
		// const url = util.urlAddParams('/app-api/pay/cart-item/createBySubOrderNo',data);
		// return http.post(url);
		return http.post('/app-api/pay/order/reJsapiOrderBySubOrderNo',data)
	},
	//申请退款
	applyRefund(data){
		return http.post('/app-api/order/refund/applyRefund',data)
	},
	//审批退款
	approveRefund(data){
		return http.post('/app-api/order/refund/approveRefund',data)
	},

	//通过主单orderNo获得订单支付详情
	getpayOrder(data){
		const url = util.urlAddParams('/app-api/order/mp/getOrder',data);
		return http.get(url);
	},

	//订单支付的取消
	cancelPayOrder(data){
		// /app-api/pay/order/cancelBySubOrderNo
		// const url = util.urlAddParams('/app-api/pay/order/cancelByOrderNo', data);
		return http.put('/app-api/pay/order/cancelByOrderNo', data);
	}
};
