/**
 * 返回科学计数法表示的小数位数，如digitLength(3e-7)返回7，因为3e-7是0.0000003的科学计数法表示
 *
 * @param {*} num
 * @returns
 */
function digitLength(num = 0) {
	const eSplit = num.toString().split(/[eE]/);
	const len = (eSplit[0].split('.')[1] || '').length - +(eSplit[1] || 0);

	return len > 0 ? len : 0;
}

/**
 * 把小数转成整数，支持科学计数法。如果是小数则放大成整数
 * @param {*number} num 输入数
 */
function float2Fixed(num = 0) {
	if (num.toString().indexOf('e') === -1) {
		return Number(num.toString().replace('.', ''));
	}

	const dLen = digitLength(num);

	return dLen > 0 ? num * 10 ** dLen : num;
}

/**
 * 精确加法
 */
function plus(...args) {
	const [num1, num2] = args;
	const others = args.slice(2);

	if (others.length) {
		return plus(...[plus(num1, num2), ...others]);
	}

	const baseNum = 10 ** Math.max(digitLength(num1), digitLength(num2));

	return (times(num1, baseNum) + times(num2, baseNum)) / baseNum;
}

/**
 * 精确减法
 */
function minus(...args) {
	const [num1, num2] = args;
	const others = args.slice(2);

	if (others.length) {
		return minus(...[minus(num1, num2), ...others]);
	}

	const baseNum = 10 ** Math.max(digitLength(num1), digitLength(num2));

	return (times(num1, baseNum) - times(num2, baseNum)) / baseNum;
}
/**
 * 精确乘法
 */
function times(...args) {
	const [num1, num2] = args;
	const others = args.slice(2);

	if (others.length) {
		return times(...[times(num1, num2), ...others]);
	}

	const num1Changed = float2Fixed(num1);
	const num2Changed = float2Fixed(num2);
	const baseNum = digitLength(num1) + digitLength(num2);
	const leftValue = num1Changed * num2Changed;

	return leftValue / 10 ** baseNum;
}

/**
 * 精确除法
 */
function divide(...args) {
	const [num1, num2] = args;
	const others = args.slice(2);

	if (others.length) {
		return divide(...[divide(num1, num2), ...others]);
	}

	const num1Changed = float2Fixed(num1);
	const num2Changed = float2Fixed(num2);

	return times(num1Changed / num2Changed, 10 ** (digitLength(num2) - digitLength(num1)));
}

/**
 * 四舍五入
 */
/**
 *
 *
 * @param {*} num 浮点数
 * @param {*} ratio 小数位数
 * @returns
 */
function round(num, ratio) {
	const base = 10 ** ratio;

	return divide(Math.round(times(num, base)), base);
}

/**
 * 金额格式化：千分位+两位小数
 * @amount {Float} 浮点数
 * @return {Str}
 */
function formatAmount(amount) {
	return String(round(Number(amount), 2)).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

/**
 * 截取整数部分多少位
 *
 * @param {*} value 要截取的数字
 * @param {*} figures 截取位数
 * @param {string} [trimType='low'] 截取类型，low表示从低位开始截取，high表示从高位开始截取
 * @returns {number} 截取后的数字
 */
function trimInteger(value, figures, trimType = 'high') {
	const [integer, decimal] = value.toString().split('.');

	const newInteger = trimType === 'low' ? integer.slice(-figures) : integer.slice(0, figures);

	if (decimal) {
		return Number(`${newInteger}.${decimal}`);
	}

	return Number(`${newInteger}`);
}

export default { plus, minus, times, divide, formatAmount, round, trimInteger };
