import { util } from '@/utils/';
import http from '../request/index';

export default {

  // 获得商品详情，根据商品id获得商品详情
  getdetail(data) {
    const url = util.urlAddParams('/app-api/prod/mall-product/productGet', data);
    return http.get(url, { timeout: 100000 });
  },
};
