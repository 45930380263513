/**
 * 单例模式重新封装sessionStorage方法
 * 第一步：添加一个私有的构造器方法
 * 第二步：至少提供一个外部访问的方法或者属性，外部可以通过这个方法或则属性来得倒实例，所以这个方法或者属性应该为静态方法
 * 第三步： 外部调用第二步提供的方法或者属性获取实例对象
 */
class SPStorage {
  static instance;

  constructor() {
    console.warn('这是构造器！');
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new SPStorage();
    }
    return this.instance;
  }

  setItem(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key) {
    const value = sessionStorage.getItem(key);
    return value !== null ? JSON.parse(value) : null;
  }
}

const storage = SPStorage.getInstance();

export default storage;
