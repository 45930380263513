/*
 * 将Promisr的结果和错误，转化为一个数组返回。
 * @params {Promise}
 * @return {array} [err, res]
 */
const to = (promise) => promise.then((res) => [null, res]).catch((err) => [err, null]);

/**
 * 为url添加参数，如果参数已存在，则覆盖其值，返回修改后的url
 * @param {String} url - 要添加参数的url
 * @param {Object} params - 参数对象
 * @returns {String} - 修改后的url
 */
function urlAddParams(url, params) {
	if (!params) {
		return url;
	}

	const [basicUrl, originParamsStr = ''] = url.split('?');
	const originParamsArr = originParamsStr.split('&');
	const originParamsObj = {};

	originParamsArr.forEach((item) => {
		const [key, value = ''] = item.split('=');

		if (key) {
			originParamsObj[key] = value;
		}
	});

	Object.keys(params).forEach((key) => {
		originParamsObj[key] = params[key];
	});

	const newParamsStr = Object.keys(cleanEmptyProps(originParamsObj))
		.map((key) => `${key}=${originParamsObj[key]}`)
		.join('&');

	return `${basicUrl}?${newParamsStr}`;
}

/**
 * 清除对象的空属性(只检查一级属性)
 *
 * @param {*} obj
 * @returns
 */
function cleanEmptyProps(obj) {
	const newObj = { ...obj };

	Object.keys(newObj).forEach((key) => {
		const value = newObj[key];

		if (isNull(value) || isUndefined(value) || isEmptyObject(value) || isEmptyArray(value)) {
			delete newObj[key];
		}
	});

	return newObj;
}

/**
 * 是否为Null类型
 *
 * @param {*} obj
 * @returns
 */
function isNull(obj) {
	return Object.prototype.toString.call(obj) === '[object Null]';
}

/**
 * 是否为Undefined类型
 *
 * @param {*} obj
 * @returns
 */
function isUndefined(obj) {
	return Object.prototype.toString.call(obj) === '[object Undefined]';
}

/**
 * 是否为空对象
 *
 * @param {*} obj
 * @returns
 */
function isEmptyObject(obj) {
	return isObject(obj) && Object.keys(obj).length === 0;
}

/**
 * 是否为空数组
 *
 * @param {*} arr
 * @returns
 */
function isEmptyArray(arr) {
	return isArray(arr) && arr.length === 0;
}

/**
 * 是否为Array类型
 *
 * @param {*} obj
 * @returns
 */
function isArray(obj) {
	return Object.prototype.toString.call(obj) === '[object Array]';
}

/**
 * 是否为Object类型
 *
 * @param {*} obj
 * @returns
 */
function isObject(obj) {
	return Object.prototype.toString.call(obj) === '[object Object]';
}

/**
 * 验证是否为手机号
 * @param data 手机号
 * @returns
 */
export function isPhoneNum(data) {
	const reg = /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;

	return reg.test(data);
}

/**
 * 函数防抖
 *
 * @param {function} func 要执行的函数
 * @param {number} t 等待时间
 */
function debounce(func, t) {
	const delay = t || 500;
	let timer = null;
	// eslint-disable-next-line func-names
	return function () {
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			// eslint-disable-next-line prefer-rest-params
			func.apply(this, arguments);
		}, delay);
	};
}

/**
 * 手机号脱敏
 * @param {*} phone 手机号
 * @returns
 */
function formatPhome(phone) {
	if (!phone) {
		return '';
	}
	return `${phone.substr(0, 3)}****${phone.substr(7)}`;
}

/**
 * 排除假植
 * @param {*} obj
 */
function filterObjectFalsen(obj = {}) {
	const newObj = obj;
	Object.keys(newObj).forEach((key) => !newObj[key] && delete obj[key]);
	return newObj;
}

/**
 * 是否屏蔽全局console.log
 * @param {*} boolean 是否显示打印
 */
function Remove_Log(val) {
	const isDebug = val; // 控制是否屏蔽全局console.log 日志；isDebug设为false即可屏蔽
	console.log = (function (oldLog) {
		return function () {
			if (isDebug) {
				oldLog.apply(this, arguments);
			}
		};
	})(console.log);
}

export default {
	to,
	urlAddParams,
	isPhoneNum,
	debounce,
	formatPhome,
	filterObjectFalsen,
	Remove_Log
};
